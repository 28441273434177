@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
  font-family: 'Roboto Mono';
  color: lightgray;
  background-color:#0d1117;
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: white;
}

#header{
  font-family: 'Ubuntu';
  height: 100vh; 
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

#header_wrapper {
  margin: 50px;
  padding: 50px;
  min-width: 220px;
  max-width: 500px;
  display: 'flex';
  flex-direction: 'column';
}

#profile_image {
  height:25%;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border-radius: '50%';
  object-fit: cover;
}

#title {
  text-align: center;
  padding:.5em;
}

#subtext {
  position: absolute;
  text-align: center;
  top: 50%;
  padding:.5em;
}

#header_icons > a {
  padding:12px;
  color: inherit;
  display: 'flex';
  justify-content: center;
  flex-wrap: 'wrap';
  gap: 10px;
}

#header_icons > a:hover {
  color:white;
}

#header_bottom {
  position: absolute;
  top: 89%;
  padding-bottom: 10px;
  text-align: center;
}

.centered {
  text-align: center;
}